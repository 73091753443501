import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Bench Press 5×2\\@70%1RM `}<em parentName="p">{`w/:03 pause on chest, fast ascent`}</em></p>
    <p>{`Strict Wt’d Chin Ups 5×2`}</p>
    <p>{`then,`}</p>
    <p>{`500M Ski Erg Buy In,`}</p>
    <p>{`3 Rounds of:`}</p>
    <p>{`20-Ring Pushups`}</p>
    <p>{`20-GHD Situps (scale to v ups)`}</p>
    <p>{`20-KBS’s (53/35)(RX+ 70/53)`}</p>
    <p>{`500M Ski Erg Cash Out.`}</p>
    <p>{`For time.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      